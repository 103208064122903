import { Outlet } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/server-runtime';

import loadAuthPageData from '~/lib/server/loaders/load-auth-page-data.server';
import AuthPageShell from '~/components/auth/AuthPageShell';

export const loader = (args: LoaderFunctionArgs) => loadAuthPageData(args);

function AuthLayout() {
  return (
    <AuthPageShell>
      <Outlet />
    </AuthPageShell>
  );
}

export default AuthLayout;
